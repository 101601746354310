import DatePicker from '@mui/lab/DatePicker';
import AdapterDayjs from '@mui/lab/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { useField } from 'formik';
import { useCallback, useMemo } from 'react';
import { DefaultTextField } from '../FormikTextField';
import { Button } from '@mui/material';
import {
  clearButtonStyles,
  hideDatePickerStyles
} from './styles';

type FormikDatePickerProp = {
  name: string;
  label: string;
  maxDate?: unknown;
  minDate?: unknown;
  id?: string;
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
};

export function FormikDatePicker({
  name,
  label,
  maxDate,
  minDate,
  id,
  disabled,
  helperText = '',
  clearable,
}: FormikDatePickerProp): JSX.Element {
  const [field, meta] = useField({ name });
  const { value, touched, error } = meta;
  const shouldShowError = !!touched && !!error;
  const { onChange } = field;
  const hideDatePickerIcon = clearable && !!value && value !== '';

  const handleDateChange = useCallback(
    (value) => {
      onChange({ target: { name, value } });
    },
    [onChange, name]
  );

  const renderInput = useCallback(
    (params) => (
      <DefaultTextField
        error={shouldShowError}
        helperText={shouldShowError ? error : helperText}
        id={id || name}
        InputLabelProps={{ htmlFor: params.id || params.name }}
        name={name}
        {...params}
      />
    ),
    [shouldShowError, error, helperText, name, id]
  );

  const renderedDatepicker = useMemo(
    () => (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          {...field}
          label={label}
          maxDate={maxDate}
          minDate={minDate}
          onChange={handleDateChange}
          renderInput={renderInput}
          disabled={disabled}
          inputFormat={'DD/MM/YYYY'}
          InputProps={{
            endAdornment: (
              <Button onClick={() => handleDateChange(null)} sx={clearButtonStyles}>
                Clear
              </Button>
            )
          }}
          InputAdornmentProps={{
            position: hideDatePickerIcon ? "start" : "end",
            sx: hideDatePickerIcon ? hideDatePickerStyles : undefined,
          }}
        />
      </LocalizationProvider>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      shouldShowError,
      error,
      name,
      disabled,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(field),
    ]
  );

  return renderedDatepicker;
}
